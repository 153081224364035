import { Event, Gift } from "../../FirestoreConverters";
import { Link } from "react-router-dom";
import { QueryDocumentSnapshot } from "firebase/firestore";
import Loading from "../General/Loading";
import GeneralContributionCard from "./GeneralContributionCard";
import GiftingContributionCard from "./GiftingContributionCard";

interface GiftListProps {
  event: Event;
  contributions: QueryDocumentSnapshot<Gift>[] | undefined;
  showMessages?: boolean;
  showAmounts?: boolean;
  setBillToRepeat: (bill: Gift) => void;
}

const PaidPaymentsList = ({event, contributions, showAmounts, showMessages, setBillToRepeat}: GiftListProps) => {
  // Loading the data
  if (contributions === undefined) return <div className="p-4"><Loading /></div>

  // Something went wrong
  // if (status === "error") return <p className="p-2">Sorry, we couldn't load contributions right now. Please try again later.</p>

  // Open event, but no contributions
  if (contributions.length === 0 && !event.hasEnded) return <>
    <p className="p-2 text-center">No contributions yet.</p>
    <p className="p-2 text-center">Remember to share your jar via the QR Code or link.</p>
  </>

  // Closed event, but no contributions
  if (contributions.length === 0 && event.hasEnded) return <>
    <p className="p-2 text-center">
      Your jar had no contributions. Why not <Link to="/create-jar" className="font-bold underline">create a new one?</Link>
    </p>
  </>

  return <div>
    {contributions.map((row) => {
      const gift = row.data()
      return <div className="border-b last:border-b-0" key={gift.id}>
        {event.isGifting === true ?
          <GiftingContributionCard contribution={gift} showAmounts={showAmounts} showMessages={showMessages} />
        :
          <GeneralContributionCard contribution={gift} setBillToRepeat={setBillToRepeat} eventHasEnded={event.hasEnded} />
        }
      </div>
    })}
    {/* {giftsData.size >= (giftLimit*parseInt(searchParams.get("page") || "1")) && <div><LoadMoreButton handler={loadMore} /></div>} */}
  </div>
}

export default PaidPaymentsList

// interface LMProps {
//   handler: () => void
// }
// const LoadMoreButton = ({handler}: LMProps) => {
//   return (<>
//     <div className="text-center py-8">
//       <LinkButton
//         type="button"
//         clickHandler={handler}
//       >
//         Load More
//       </LinkButton>
//     </div>
//   </>)
// }