import {
  Outlet,
  useParams
} from "react-router-dom";
import {
  doc,
  DocumentSnapshot,
  FirestoreError,
  onSnapshot
} from 'firebase/firestore';
import {
  useAuth,
  useFirestore,
  useSigninCheck
} from 'reactfire';

import {
  useEffect,
  useState } from "react";

import NotFound from "../General/NotFound";
import Loading from "../General/Loading";
import { Event/*, User as Organiser*/, FirestoreEventConverter,/*, FirestoreUserConverter*/
JarStatus} from "../../FirestoreConverters";
import { Auth, User, signInAnonymously } from "firebase/auth";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import Hero from "../General/Hero";

export default function GiftToEvent () {

  const [, setDocumentTitle] = useDocumentTitle()

  const firestore = useFirestore()
  const {eventName} = useParams()
  const [eventData, setEventData] = useState<Event | undefined | false>(undefined)
  //const [organiserData, setOrganiserData] = useState<Organiser | undefined | false>(undefined)
  const [user, setUser] = useState<false | User>(false)

  const auth = useAuth()
  const {status: signInStatus, data: signInCheckResult} = useSigninCheck()

  useEffect(() => {
    const loginAnon = async (auth: Auth) => {
      const u = await signInAnonymously(auth)
      return u
    }

    if (signInStatus === "loading") return;
    if (signInStatus === 'success' && signInCheckResult.signedIn === false) {
      loginAnon(auth)
    }

    if (signInStatus === 'success' && signInCheckResult.signedIn === true) {
      setUser(signInCheckResult.user)
    }
  }, [auth, signInStatus, signInCheckResult])

  /**
   * Get the event information using the URL slug
   **/
  useEffect(() => {
    if (user === false || eventName === undefined) {
      return
    }
    const streamEvent = (eventId: string | undefined, snapshotHandler: (snapshot: DocumentSnapshot<Event>) => void, errorHandler: (e: FirestoreError) => void) => {
      if (eventId === undefined) return
      const eventRef = doc(firestore, `events/${eventId}`)
        .withConverter(FirestoreEventConverter);
      return onSnapshot(eventRef, snapshotHandler, errorHandler)
    }
    const unsub = streamEvent(eventName,
      (snapshot) => {
        const event = snapshot.data()
        if (event === undefined) {
          return setEventData(false)
        }
        setEventData(event)
        setDocumentTitle(`Contribute to ${event.name}`)
      },
      (e) => {
        console.log(e)
        setEventData(false)
      }
    )
    return unsub
  }, [eventName, firestore, user, setDocumentTitle])

  let content = <Loading />;
  let title = "Loading...";
  let subTitle = undefined;
  if (eventData !== false && eventData !== undefined && user !== false && eventData.hasEnded === false && eventData.status === JarStatus.OPEN) {
    content = <Outlet context={{eventInfo: eventData, userInfo: user}} />
    title = eventData.name
    subTitle = eventData.date !== null ? eventData.date.toLocaleDateString() : undefined
  } else if (eventData !== false && eventData !== undefined && (eventData.hasEnded === true || eventData.status !== JarStatus.OPEN)) {
    content = <>
      <p className="text-my-grey font-bold pt-4 text-xl uppercase">This jar is now closed!</p>
      <p className="py-4 font-beloved">{eventData.message}</p>
    </>
    title = eventData.name
  } else if (eventData === false) {
    content = <NotFound />
    title = "Oops!"
  }

  return (
    <div className="bg-white">
      <Hero
        // bg={`bg-hero-${eventData && eventData.isGifting? eventData.type : "confetti"}`}
        title={title}
        sub={subTitle}
      />

      <div className="mx-auto max-w-screen-lg text-my-grey p-4">
        {content}
      </div>
    </div>
  )
}