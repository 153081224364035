import {
  collection,
  doc,
  DocumentSnapshot,
  FirestoreError,
  onSnapshot,
  orderBy,
  query,
  QuerySnapshot,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import {
  Outlet,
  useOutletContext,
  useParams
} from "react-router-dom";
import { useFirestore } from "reactfire";
import { Event, FirestoreEventConverter, FirestoreGiftConverter, Gift, PaymentStatus } from "../../FirestoreConverters";
import LinkButton from "../General/LinkButton";
import Loading from "../General/Loading";
import { MyAccountProps } from "./MyAccount";

export default function EventAdmin () {

  const {authUser, userInfo, setDocumentTitle, setSubTitle} = useOutletContext<MyAccountProps>()

  const firestore = useFirestore()
  const {eventName} = useParams()

  const [eventData, setEventData] = useState<Event | undefined | false>(undefined)
  useEffect(() => {
    const streamEvent = (eventId: string | undefined, snapshotHandler: (snapshot: DocumentSnapshot<Event>) => void, errorHandler: (e: FirestoreError) => void) => {
      if (eventId === undefined) return
      const eventRef = doc(firestore, `events/${eventId}`)
        .withConverter(FirestoreEventConverter);
      return onSnapshot(eventRef, snapshotHandler, errorHandler)
    }

    const unsub = streamEvent(eventName,
      (snapshot) => {
        const event = snapshot.data()
        if (event === undefined || event.organiser.path !== `users/${authUser.uid}`) {
          setEventData(false)
          return
        }
        setEventData(event)
      },
      (e) => {
        console.log(e)
        setEventData(false)
      }
    )
    return unsub
  }, [eventName, setEventData, firestore, authUser])

  const [contributions, setContributions] = useState<QuerySnapshot<Gift>>()
  useEffect(() => {
    if (eventData === undefined || eventData === false) return

    const streamContributions = (eventId: string, snapshotHandler: (snapshot: QuerySnapshot<Gift>) => void, errorHandler: (e: FirestoreError) => void) => {
      const giftsRef = collection(firestore, `events/${eventId}/gifts`)
        .withConverter(FirestoreGiftConverter)
      const giftsQuery = query(
        giftsRef,
        where("status", "in",
          [
            PaymentStatus.PAID,
            PaymentStatus.PENDING,
            PaymentStatus.FAILED,
            PaymentStatus.CANCELLED
          ]
        ),
        orderBy("giftedAt", "desc"),
      )
      return onSnapshot(giftsQuery, snapshotHandler, errorHandler)
    }

    const unsub = streamContributions(eventData.id,
      (snapshot) => {
        setContributions(snapshot)
      },
      (e) => {
        setContributions(undefined)
      }
    )
    return unsub

  }, [firestore, eventData])

  // Undefined: We don't know if the event or gifts exist yet
  // False: Event or gifts could not be found
  if (eventData === undefined) {
    return <Loading />
  }

  if (eventData === false) {
    return <div className="p-4">
      <p className="text-my-grey p-2">We're sorry, but we could'nt find the jar you were looking for.</p>
      <LinkButton type="link" url="/create-jar">Create Jar</LinkButton>
    </div>
  }

  return <Outlet context={{event: eventData, contributions: contributions, authUser, userInfo, setDocumentTitle, setSubTitle}} />
}