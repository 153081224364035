import { Link } from "react-router-dom"
import { Event } from "../../FirestoreConverters"
import LinkButton from "../General/LinkButton";

interface EventCardProps {
  event: Event;
}
const EventCard = ({event}: EventCardProps) => {
  return (
    <div
      className="grid gap-4 grid-cols-3 px-4 py-4 first:pt-0 border-b last:border-none hover:sm:rounded-md hover:bg-neutral-100"
    >
      <div className="col-span-2">
        <h4 className="text-lg font-bold">
          <Link to={`/jars/${event.id}`} className="hover:text-my-olive capitalize">
            {event.name}
          </Link>
        </h4>
        <p>
        {event.date !== null ?
          `${event.hasEnded ? "Closed: " : "Closes: "} ${event.date.toLocaleDateString()}`
          : "Always open"
        }
        </p>
      </div>
      <div className="text-center my-auto">
        <LinkButton type="link" url={`/jars/${event.id}`} backgroundColour="my-olive">
          View
        </LinkButton>
      </div>
    </div>
  )
}

export default EventCard