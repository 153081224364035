import { User as AuthUser } from "firebase/auth";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import {
  useFirestore,
  useSigninCheck
} from "reactfire"
import LoginOrRegister from "./LoginOrRegister";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import Hero from "../General/Hero";
import Loading from "../General/Loading";
import { Event, FirestoreUserConverter, User, User as UserInfo } from "../../FirestoreConverters";
import { doc, DocumentReference, DocumentSnapshot, FirestoreError, onSnapshot } from "firebase/firestore";

export interface MyAccountProps {
  event?: Event;
  authUser: AuthUser;
  userInfo: UserInfo;
  setDocumentTitle: (t: string) => void;
  setSubTitle: (t: string) => void;
}

export default function MyAccount () {
  const firestore = useFirestore()

  const [authUser, setAuthUser] = useState<AuthUser | null>(null)
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null)
  const [title, setDocumentTitle] = useDocumentTitle()
  const [subTitle, setSubTitle] = useState()

  const { status: authStatus, data: signInCheckResult } = useSigninCheck();

  /*
  I think there's a bug here, more a race condition, where when a user logs in
  their auth status isn't set and we can't render the my-account page
  */
  useEffect(() => {
    if (
      authStatus !== 'loading' && authStatus !== 'error' &&
      signInCheckResult.signedIn === true &&
      signInCheckResult.user?.isAnonymous === false
    ) {
      setAuthUser(signInCheckResult.user)
    } else {
      setAuthUser(null)
    }
  }, [signInCheckResult, authStatus])

  useEffect(() => {
    if (authUser === null) return

    const streamUser = (userRef: DocumentReference<User>, snapshotHandler: (snapshot: DocumentSnapshot<User>) => void, errorHandler: (e: FirestoreError) => void ) => {
      return onSnapshot(userRef, snapshotHandler, errorHandler)
    }

    const ref = doc(firestore, `users/${authUser.uid}`)
      .withConverter(FirestoreUserConverter)

    const unsub = streamUser(
      ref,
      (snapshot) => {
        const userData = snapshot.data()

        if (userData !== undefined) {
          setUserInfo(userData)
        }
      },
      (e) => {
        console.log(e)
      })
    return unsub
  }, [authUser, firestore, setUserInfo])

  if (authStatus === 'loading' && userInfo === null) {
    return <Loading />
  }

  if (authUser === null && userInfo === null) {
    return <LoginOrRegister setUser={setAuthUser} />;
  }

  return <>
    <Hero title={title} sub={subTitle} />
    <div className="max-w-screen-lg mx-auto w-full md:p-4 flex-grow flex flex-col">
      <Outlet context={{authUser, userInfo, setDocumentTitle, setSubTitle}} />
    </div>
  </>
}