import { and, collection, doc, getDocs, or, orderBy, query, QueryDocumentSnapshot, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useFirestore } from "reactfire";
import { Event, FirestoreEventConverter, JarStatus } from "../../FirestoreConverters";
import LinkButton from "../General/LinkButton";
import Loading from "../General/Loading";
import EventCard from "../Event/EventCard";
import { MyAccountProps } from "./MyAccount";

export default function Dashboard () {
  const {authUser, setDocumentTitle, setSubTitle} = useOutletContext<MyAccountProps>()
  setDocumentTitle("Your Jars")
  setSubTitle(`Hello, ${authUser.displayName}`)
  const firestore = useFirestore()

  const [ongoingEvents, setOngoingEvents] = useState<undefined | Array<Event>>(undefined)
  useEffect(() => {

    const eventsRef = collection(firestore, "events").withConverter(FirestoreEventConverter)
    const userRef = doc(firestore, `/users/${authUser.uid}`)
    const q = query(
      eventsRef,
      and(
        where("organiser", "==", userRef),
        or(
          where("date", "==", null),
          where("date", ">", new Date()),
        ),
        where("status", "==", JarStatus.OPEN),
      ),
      orderBy("date", "asc")
    )

    getDocs(q).then((events) => {
      const ongoingEvents: Array<Event> = []

      events.forEach((doc: QueryDocumentSnapshot<Event>) => {
        const e = doc.data()
        ongoingEvents.push(e)
      })
      setOngoingEvents(ongoingEvents)
    }).catch((e) => {
      console.log(e)
    })
  }, [authUser, firestore])

  const [pastEvents, setPastEvents] = useState<undefined | Array<Event>>(undefined)
  useEffect(() => {

    const eventsRef = collection(firestore, "events").withConverter(FirestoreEventConverter)
    const userRef = doc(firestore, `/users/${authUser.uid}`)
    const q = query(eventsRef,
      where("organiser", "==", userRef),
      // or(
        where("date", "<", new Date()),

      // ),
      // Need to fetch "open" at this point as when date passes, we don't close the jar
      where("status", "in", [JarStatus.OPEN, JarStatus.CLOSED]),
      orderBy("date", "desc")
    )

    getDocs(q).then((events) => {

      const pastEvents: Array<Event> = []

      events.forEach((doc: QueryDocumentSnapshot<Event>) => {
        const e = doc.data()
        pastEvents.push(e)
      })
      setPastEvents(pastEvents)
    }).catch((e) => {
      console.log(e)
    })
  }, [authUser, firestore])

  return (<>
    <div className="w-full mx-auto max-w-screen-lg text-left grid grid-cols-1 md:grid-cols-2 gap-4 md:px-2">
      <div>
        <h3 className="uppercase text-xl p-4 text-white bg-my-orange">Open Jars</h3>
        {ongoingEvents === undefined ? <Loading /> :
        ongoingEvents.length === 0  ? <p className="p-4">You have no open jars. Why not create one now?</p> :
        ongoingEvents.map((event: Event) => {
          return <EventCard event={event} key={event.id} />
        })}
      </div>
      <div>
        <h3 className="uppercase text-xl p-4 text-white bg-my-orange">closed Jars</h3>
        {pastEvents === undefined ? <Loading /> :
        pastEvents.length === 0 ? <p className="p-4">You have no closed Jars</p> :
        pastEvents.map(event => {
          return <EventCard event={event} key={event.id} />
        })}
      </div>
    </div>
    <div className="py-6 p-4 md:pt-0 text-center">
      <LinkButton type="link" url="/create-jar" backgroundColour="my-olive">Create a Jar</LinkButton>
    </div>
  </>)
}