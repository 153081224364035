import Hero from "../General/Hero"
import LinkButton from "../General/LinkButton"
import {ReactComponent as ChatOnWhatsApp} from "../Icons/WhatsAppButtonGreenLarge.svg";

const Contact = () => {
  return <>
    <Hero title="Contact Us"/>
    <div className="max-w-screen-lg mx-auto w-full md:p-4 flex-grow flex flex-col">
      <div className="text-center my-auto p-4 md:p-0">
        <p>Got any questions? We're happy to assist!</p>
        <p>Please let us know how we can help by using the options below.</p>
        <div className="max-w-screen-md mx-auto pt-4">
          <LinkButton type="link" url="mailto:support@flossipay.com">Send us an email</LinkButton>
          <div className="relative flex py-5 items-center">
            <div className="flex-grow border-t border-my-olive"></div>
            <span className="flex-shrink mx-4 text-my-olive">Or</span>
            <div className="flex-grow border-t border-my-olive"></div>
          </div>
        </div>
        <a href="https://wa.me/+447822013827">
          <ChatOnWhatsApp className="text-center mx-auto" />
        </a>
      </div>
    </div>
  </>
}

export default Contact