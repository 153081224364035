import { User } from 'firebase/auth';
import {
  addDoc,
  collection,
  doc,
  Timestamp,
  updateDoc
} from 'firebase/firestore';
import {
  useEffect,
  useState
} from "react";
import {
  SubmitHandler,
  useForm
} from 'react-hook-form';
import {
  useNavigate,
} from 'react-router-dom';
import {
  useAnalytics,
  useFirestore
} from "reactfire";
import { BaseEvent, Event, JarStatus } from "../../FirestoreConverters";
import { logEvent } from "firebase/analytics";
import { CollectionTypes } from "../Page/CreateEvent";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import LinkButton from "../General/LinkButton";
import Modal from "../General/Modal";

type GeneralFormValues = {
  eventName: string;
  eventType: string;
  feeIncluded: string;
  message: string;
}

type FormProps = {
  event?: Event,
  user: User,
  setEventType?: (type: string) => void,
}

export const eventTypeList = [
  {
    value: "",
    name: "Select a Jar Type",
  },
  {
    value: "group",
    name: "Collection Between Friends",
  },
  {
    value: "event",
    name: "Organising an Event",
  },
  {
    value: "tips",
    name: "Tips at Work",
  },
  {
    value: "popup",
    name: "Pop Up Shop",
  },
  {
    value: "nonprofit",
    name: "Non-Profit",
  },
  {
    value: "club",
    name: "Amateur Sports Club",
  },
  {
    value: "business",
    name: "Business",
  },
  {
    value: "custom",
    name: "Other",
  }
]

export const CreateGeneralForm = ({user, event}: FormProps) => {

  const [, setDocumentTitle] = useDocumentTitle("Create Jar")
  useEffect(() => {
    if(event) setDocumentTitle(`Edit Jar "${event.name}"`)
  }, [event, setDocumentTitle])

  const {
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<GeneralFormValues>({
    mode: "onChange"
  });
  const firestore = useFirestore()
  const navigate = useNavigate()
  const analytics = useAnalytics()

  const saveEvent = async ({eventName, eventType, message, feeIncluded}: GeneralFormValues) => {
    // We need the organiser ref
    if (!user) throw new Error("User is null")

    // Check if an event is in the past
    if (event && event.hasEnded) throw new Error("Can't update past event")

    // Create the user ref
    const userRef = doc(firestore, "users", user.uid)

    // Construct the occasion data to save
    const eventData: BaseEvent = {
      //@todo This needs to be change to use Timestamp
      date: null,
      name: eventName.trim(),
      message: message.trim(),
      type: eventType,
      isGifting: false,
      feeIncluded: feeIncluded === "true" ? true : false,
      organiser: userRef,
      createdAt: event?.createdAt || new Timestamp(Date.now()/1000, 0),
      status: JarStatus.OPEN,
    }

    // If we already have an event, then we update, if not, we save
    let eventRef
    if (event) {
      eventRef = doc(firestore, "events", event.id)
      await updateDoc(eventRef, eventData)
    } else {
      eventRef = await addDoc(collection(firestore, "events"), eventData)
    }
    return eventRef
  }

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const onSubmit: SubmitHandler<GeneralFormValues> = async data => {

    setIsLoading(true)
    try {
      const event = await saveEvent(data)
      logEvent(analytics, "event_create", {
        jarTheme: data.eventType,
        jarType: CollectionTypes.GENERAL
      })
      navigate(`/jars/${event.id}`)
    } catch (e: any) {
      setIsLoading(false)
      logEvent(analytics, "exception", {
        description: e.toString()
      })
    }
  }

  const [showFeeInfoModal, setShowFeeInfoModal] = useState<boolean>(false)

  const eventOptions = eventTypeList.map((v) => {
    return <option value={v.value} key={v.value}>{v.name}</option>
  })
  const headingClass = "text-center bg-my-olive text-white border border-my-olive uppercase p-1"
  const cellClass = "p-1 border border-my-grey"

  return (
    <div className="create-occasion-form text-center">
      {showFeeInfoModal === true ?
        <Modal
          closeFunction={() => setShowFeeInfoModal(false)}
          title="Who pays the fees?"
        >
          <>
            <p className="py-2 text-left">Flossi apply a small transaction fee to each payment.</p>
            <table className="w-full max-w-sm mx-auto text-xs my-2">
              <tbody>
                <tr>
                  <th colSpan={2} scope="col" className={headingClass}>
                    Service/Transaction Fees
                  </th>
                </tr>
                <tr>
                  <td className={`${cellClass} border-t-0`}>
                    Payments less than £2
                  </td>
                  <td className={`${cellClass} border-t-0`}>
                    £0.15
                  </td>
                </tr>
                <tr>
                  <td className={`${cellClass}`}>
                    Payments from £2 to £3000
                  </td>
                  <td className={`${cellClass}`}>
                    1% + £0.20
                  </td>
                </tr>
              </tbody>
            </table>
            <p className="py-2 text-left">As the jar owner, <span className="font-bold">you can choose</span> if you or the contributor will pay the transaction fee. The following £10 payment example presents your options.</p>
            <h1 className="underline text-left font-bold">Option 1 - Jar Owner Pays Fee</h1>
            <p className="text-left">
              The fee is taken from the total contribution amount and is therefore paid for by the jar owner.
            </p>
            <ul className="text-left py-2">
              <li>Contributor Pays: £10.00</li>
              <li>Jar Owner Receives: £9.70 <span className="text-sm">(£10.00 minus £0.30 transaction fee)</span></li>
            </ul>
            <h1 className="underline text-left font-bold">Option 2 - Contributor Pays Fee</h1>
            <p className="text-left">The fee is added to the contribution amount and is therefore paid for by the contributor.</p>
            <ul className="text-left py-2">
              <li>Contributor Pays: £10.30 <span className="text-sm">(£10.00 plus £0.30 transaction fee)</span></li>
              <li>Jar Owner Receives: £10.00</li>
            </ul>
          </>
        </Modal>
      : null}
      {event === undefined ? <p className="">Fill out the information below to create your jar!</p> : <p className="pb-4">Use the form below to update your jar.</p>}
      <form onSubmit={handleSubmit(onSubmit)} >
        <fieldset disabled={isLoading === true || (event && event.hasEnded)}>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
            <div className="col-span-2 md:col-span-4">
              <label className="block text-left uppercase font-bold py-2">
                Jar Name:
              </label>
              <input
                type="text"
                placeholder="Give your jar a name (The Cake Shop, Anna's Hen Do)"
                {...register("eventName", {required: "Please enter a name for your jar", maxLength: 150})}
                className="border p-2 w-full border-my-grey h-10 align-middle text-center placeholder:text-my-grey outline-my-orange capitalize"
                defaultValue={event?.name}
              />
              {errors.eventName && <p className="text-red-600">{errors.eventName.message}</p>}
            </div>
            <div className="col-span-2 md:col-span-4">
              <label className="block text-left uppercase font-bold py-2">
                Jar Type:
              </label>
              <select
                {
                  ...register(
                    "eventType",
                    {
                      required: "Please select the type of your jar",
                      minLength: 1
                    },
                  )
                }
                className="border p-2 w-full border-my-grey bg-white h-10 align-middle rounded-none text-center placeholder:text-my-grey outline-my-orange"
                defaultValue={event?.type}
              >
                {eventOptions}
              </select>
              {errors.eventType && <p className="text-red-600">{errors.eventType.message}</p>}
            </div>
            <label className="block text-left uppercase font-bold pt-2 col-span-2 md:col-span-4">
              Who pays the fees?
              <button type="button" onClick={() => setShowFeeInfoModal(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                </svg>
              </button>
            </label>
            <div className="md:col-span-2">
              <input type="radio"
                id="included-true" value="true" className="hidden peer"
                {...register(
                  "feeIncluded",
                  {
                    required: "Please select a fee option"
                  }
                )}
                defaultChecked={event && event.feeIncluded ? true : false}
              />
              <label htmlFor="included-true"
                className="inline-flex items-center justify-between h-full p-3 w-full border border-my-grey cursor-pointer peer-checked:text-white peer-checked:bg-my-olive hover:text-white hover:bg-my-orange peer-checked:border-my-olive peer-checked:scale-[102%] md:peer-checked:scale-100"
              >
                <div className="w-full text-sm">I will pay the small service fee for each payment</div>
              </label>
            </div>
            <div className="md:col-span-2">
              <input type="radio"
                id="included-false" value="false" className="hidden peer"
                {...register(
                  "feeIncluded",
                  {
                    required: "Please select a fee option",
                  }
                )}
                defaultChecked={event && !event.feeIncluded ? true : false}
              />
              <label htmlFor="included-false"
                className="inline-flex items-center justify-between h-full p-3 w-full border border-my-grey cursor-pointer peer-checked:text-white peer-checked:bg-my-olive hover:text-white hover:bg-my-orange peer-checked:border-my-olive peer-checked:scale-[102%] md:peer-checked:scale-100"
              >
                <div className="w-full text-sm">The contributor will pay the small service fee for their payment</div>
              </label>
            </div>
            {errors.feeIncluded && <p className="text-red-600 col-span-1 md:col-span-2">{errors.feeIncluded.message}</p>}

            <div className="col-span-2 md:col-span-4 mb-2">
              <label className="block text-left uppercase font-bold py-2">
                Describe your jar:
              </label>
              <textarea
                placeholder="Tell your contributors what you’re collecting for..."
                {...register("message", {required: "Please tell us a bit more", maxLength: {
                  value: 500,
                  message: "The maximum length is 500 characters"
                }})}
                className="border border-my-grey p-2 w-full h-32 placeholder:text-my-grey outline-my-orange"
                defaultValue={event?.message}
              />
              {errors.message && <p className="text-red-600">{errors.message.message}</p>}
            </div>
          </div>
          <LinkButton
            type="submit"
            backgroundColour="my-olive"
            disabled={isLoading}
          >
            {isLoading === true ?
              <>
                <div className="relative">{!event ? "Creating" : "Updating"}
                <svg className="animate-spin h-6 w-6 absolute left-8 inline" viewBox="0 0 24 24">
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                </div>
              </>
            : <>
            {!event ? "Create Jar!" : "Update Jar"}
            </>}
          </LinkButton>
          {/* </button> */}
        </fieldset>
      </form>
    </div>
  );
}