import { useFirestore } from "reactfire";
import { Event, FirestoreGiftConverter, Gift, PaymentStatus } from "../../FirestoreConverters"
import Modal from "../General/Modal"
import QRCode from "./QRCode"
import { useEffect, useState } from "react";
import { doc, DocumentReference, DocumentSnapshot, FirestoreError, onSnapshot } from "firebase/firestore";

interface DisplayBillQRProps {
  jar: Event;
  bill: Gift;
  closeHandler: () => void;
}

const DisplayBillQR = ({jar, bill, closeHandler}: DisplayBillQRProps) => {

  const [billData, setBillData] = useState<Gift | undefined>(bill)
  const firestore = useFirestore()
  useEffect(() => {

    const streamGift = (
      giftRef: DocumentReference<Gift>,
      snapshotHandler: (snapshot: DocumentSnapshot<Gift>) => void,
      errorHandler: (e: FirestoreError) => void
    ) => {
      console.log("Streaming gift...")
      return onSnapshot(giftRef, snapshotHandler, errorHandler)
    }

    const giftRef = doc(firestore, `events/${jar.id}/gifts/${bill.id}`)
      .withConverter(FirestoreGiftConverter)

    const unsub = streamGift(
      giftRef,
      (snapshot) => {
        console.log("got new snapshot", {snapshot})
        setBillData(snapshot.data())
      },
      (e) => {
        setBillData(undefined)
        console.log(e)
      })
    return unsub
  }, [firestore, jar, bill])

  return <Modal
    closeFunction={closeHandler}
    title={`Bill for £${bill.totalAmount.toFixed(2)}`}
  >
    {billData !== undefined && (billData.status === PaymentStatus.PENDING || billData.status === PaymentStatus.FAILED) ?
      <>
        <QRCode
          eventUrl={`http://${window.location.host}/contribute-to/${jar.id}/bill/${billData.id}`}
          eventName={jar.name}
          showDownloadButton={false}
        />

        <p className="text-center py-1">Scan the QR Code to make payment</p>
      </>
    : null}

    {billData !== undefined && billData.status === PaymentStatus.PAID ?
      <>
        <div className="mx-20">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="inline align-bottom text-my-olive">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
        </div>
        <p className="text-center">Bill has been paid</p>
      </>
    : null}

    {billData !== undefined && billData.status === PaymentStatus.CANCELLED ?
      <div className="text-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-72 inline align-bottom text-red-600">
          <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
        <p className="py-1">
          The bill payer has cancelled the bill. If you would like to recreate this bill, please do so in the 'unpaid bills' section
        </p>
      </div>
    : null}

    {billData !== undefined ?
      <div className="py-2 md:py-5 border-t border-gray-200 rounded-b text-center">
        <ul>
          {jar.feeIncluded === false ?
            <>
              <li>Transaction Fee: &pound;{billData.feeAmount.toFixed(2)}</li>
              <li>Contribution Amount: &pound;{billData.giftAmount.toFixed(2)}</li>
            </>
          : null }
          <li className="font-bold">Bill Total: &pound;{billData.totalAmount.toFixed(2)}</li>
        </ul>
      </div>
    : null}

    {billData === undefined ?
      <p>We couldn't fid the bill you were looking for!</p>
    : null}
  </Modal>
}

export default DisplayBillQR